import React from 'react'

// react router imports
import { useNavigate } from "react-router-dom";

// asset imports
import userBright from "./data/LoginButton/userBright.png"
import userDark from "./data/LoginButton/userDark.png"

function ProfileButton() {

  const navigate = useNavigate()

  const [isDark, setIsDark] = React.useState(false)
  const [variableStyles, setvariableStyles] = React.useState({
    background: "rgba(255, 243, 243, 0.1)",
  })

  const hoverOverEffect = () => {
    setIsDark(!isDark)
    setvariableStyles({background: "white"})
  }

  const hoverLeaveEffect = () => {
    setIsDark(!isDark)
    setvariableStyles({background: "rgba(255, 243, 243, 0.1)"})
  }

  const toDashboard = () => {
    navigate("/dashboard")
  }

  return (
    <div style={variableStyles} onClick={toDashboard} onMouseEnter={hoverOverEffect} onMouseLeave={hoverLeaveEffect} className='profileButton'>
      {isDark ? <img src={userDark}></img> : <img src={userBright}></img>}
    </div>
  )
}

export default ProfileButton
