//react imports
import React from "react"

import tdm from "../assets/icons/TDM.jpg"
import ProgressBar from "../assets/icons/ProgressBar.png"
import PlayerSkipLinks from "../assets/icons/PlayerSkipLinks.png"
import PlayerPlay from "../assets/icons/PlayerPlay.png"
import PlayerSkipRechts from "../assets/icons/PlayerSkipRechts.png"
import vogelfrey from "../assets/icons/Vogelfrey.jpg"

function ArtistInfo(){

    return(
      <div className="ArtistInfo container">
            <div className="MusicGridListItem row mb-14">
            <div className="artistCard col-auto">
              <img src="https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/tori%20roe.jpg?alt=media&token=8c176077-1d8e-493d-8ceb-bf49e10a6fa3"></img>
              <h5>Tori Roe</h5>
            </div>
            <div className="artistCardeins col-auto">
              <img src="https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/TDM.jpg?alt=media&token=f379cd17-7f27-431e-aa1e-465b1803321d"></img>
              <h5>The Delta Mode</h5>
            </div>
            {/* <div className="ArtistAlbumcover col-2">
              <img src={tdm}></img>
              <h5>Danny Bernath</h5>
            </div>
            <div className="Vogelfrey col-2">
              <img src={vogelfrey}></img>
              <h5>Vogelfrey</h5>
            </div> */}
          </div>
     </div>
    )
}
export default ArtistInfo

