//react component import
import MusicFilter from "./MusicFilter";

//bootstrap imports
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

//Asset import
import platformLogo from "../assets/icons/icon.png"

function SideBar(props){

  const updateQuery = (event) => {
    props.setSearchQuery(event.target.value)
  }

  function sideBarContentSelector(data) {
    if(data === "Library") {
      return (
        <div className="BrowseSideBarContent">
          <div className="titelsuche row">
          <h5> TITELSUCHE </h5>
            <div className="col">
              <input className="textSearch" value={props.searchQuery} onInput={updateQuery} type={"text"} placeholder={"Suche nach Titel oder Künstler"}></input>
            </div>
          </div>
          <MusicFilter activeTags={props.activeTags} setActiveTags={props.setActiveTags}/>
        </div>
      )
    } else if (data === "Lizensierung") {
      return <p>under construction</p>
    } else if (data === "Künstler") {
      return (
        <div>
          <div className="titelsuche row">
          <h5> Künstlersuche </h5>
            <div className="col">
              <input className="textSearch" value={props.searchQuery} onInput={updateQuery} type={"text"} placeholder={"Suche nach Künstler"}></input>
            </div>
          </div>
        </div>
      )
    } else {
      return <p>Error: sideBarContentSelector received unexpected input</p>
    }
  }

  return(
    <div className="SideBar container">
      <div className="Branding row">
        <div className="brandlogo col-3">
          <img src={platformLogo}></img>
        </div>
        <div className="brandname col">
          <h4>World Class Artist</h4>
        </div>
      </div>

      <div className="ControlPanel container">
        <div className="NavButtonsContainer row gx-0">
          <ButtonGroup className="NavButtons">
            <Button onClick={() => (props.navInput("Library"))} className="NavButton">Library</Button>
            <Button onClick={() => (props.navInput("Lizensierung"))} className="NavButton">Lizensierung</Button>
            <Button onClick={() => (props.navInput("Künstler"))} className="NavButton">Künstler</Button>
          </ButtonGroup>
        </div>

        {sideBarContentSelector(props.navState)}
      </div>
    </div>
  )
}
export default SideBar
