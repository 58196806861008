const songsData = {
    "data": [
        {
            "id": 0,
            "title": "--",
            "artist": "--",
            "album": "null",
            "genre": "null",
            "speed": "null",
            "mood": "null",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/gray.png?alt=media&token=1d90004b-727f-497f-b3aa-f703e757b8d5",
            "description": "null",
            "src": ""

        },
        {
            "id": 1,
            "title": "Far Away",
            "artist": "Lino Jednat",
            "album": "TV, Advertising, Documention",
            "genre": "Filmmusik",
            "speed": "128 BPM",
            "mood": "Epic",
            "writingQuality": "high",
            "productionQuality": "medium",
            "instrument": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreic3xatambov574nacqbutdvfufmgkb6az4jvbbmbqq2s7fktquzom.ipfs.nftstorage.link/",
            "description": "Orchestral For Documentations",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Far%20Away%20%281%29.mp3",
            "download": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Far%20Away%20%281%29.mp3"

        },
        {
            "id": 2,
            "title": "Climb To The Top",
            "artist": "Lino Jednat",
            "album": "Orchestral For Documentations",
            "genre": "Filmmusik",
            "speed": "Langsam",
            "mood": "Epic",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreic3xatambov574nacqbutdvfufmgkb6az4jvbbmbqq2s7fktquzom.ipfs.nftstorage.link/",
            "description": "Orchestral For Documentations",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Climb%20to%20the%20Top.mp3",
            "download": "https://ann.nl.tab.digital/s/PJk2fLBkDZjZSAo/download/Climb%20to%20the%20Top.mp3"
        },
        {
            "id": 3,
            "title": "Lonely Mountains",
            "artist": "Lino Jednat",
            "album": "Orchestral For Documentations",
            "genre": "Filmmusik",
            "speed": "Langsam",
            "mood": "Epic",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreic3xatambov574nacqbutdvfufmgkb6az4jvbbmbqq2s7fktquzom.ipfs.nftstorage.link/",
            "description": "Orchestral For Documentations",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Lonely%20Mountains.mp3",
            "download": "https://ann.nl.tab.digital/s/xtX4Pw5yEkHBMKm/download/Lonely%20Mountains.mp3"
        },
        {
            "id": 4,
            "title": "Returning Home",
            "artist": "Lino Jednat",
            "album": "Orchestral For Documentations",
            "genre": "Filmmusik",
            "speed": "Mittel",
            "mood": "Epic",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreic3xatambov574nacqbutdvfufmgkb6az4jvbbmbqq2s7fktquzom.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Returning%20Home%2017.37.12.mp3",
            "download": "https://ann.nl.tab.digital/s/QZL9MNmkNWtMo2r/download/Returning%20Home%2017.37.12.mp3"
        },
        {
            "id": 5,
            "title": "Feathers",
            "artist": "Dreamwood",
            "album": "Feathers",
            "genre": "Pop",
            "speed": "Langsam",
            "mood": "Happy",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreif4f2hvwv5v2tvzfryremneyprvumsw6e7sev5iplcsj2dfs3a3ya.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Dreamwood%20-%20Feathers.mp3",
            "download": "https://ann.nl.tab.digital/s/G8zR5J86tRZyr9d/download/Dreamwood%20-%20Feathers.mp3"

        },
        {
            "id": 6,
            "title": "Dejavu",
            "artist": "The Delta Mode",
            "album": "Dejavu",
            "genre": "EDM",
            "speed": "Schnell",
            "mood": "Happy",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/01%20THE%20DELTA%20MODE_-_DEJAVU%20%28online-audio-converter.com%29.mp3",
            "download": "https://ann.nl.tab.digital/s/RQDcMHyBD6QBn8B/download/01%20THE%20DELTA%20MODE_-_DEJAVU%20%28online-audio-converter.com%29.mp3"

        },
        {
            "id": 7,
            "title": "Ain't Gettin None",
            "artist": "Tori Roe",
            "album": "Ain't Gettin None",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Happy",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreicdsmghivqgbgoko5dkxov6gw543raxvl63ivolrki5nz6kxj5eqi.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://zen-germain.85-214-137-240.plesk.page/Musik%20Streaming/Tori%20Roe%20-%20Ain%27t%20Gettin%20None%20%28MSTR%29%20%28online-audio-converter.com%29.mp3"

        },
        {
            "id": 8,
            "title": "Flammenvogel",
            "artist": "Vogelfrey",
            "album": "Titanium",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreiaabwxadfnw72cgtttpsopgoy5nmotsuf4w4s2jvaczchbkhcq2c4.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeiaaiifxse7i7qwnhvunq4v6pkvfwr5q4q6oemjafkotzxa7urb6sa.ipfs.w3s.link/Flammenvogel.mp3"

        },
        {
            "id": 9,
            "title": "Recalibrate",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeigang3hciuuvb4efu5wovyrtbx6n5lvjapqyni6gkwf7w3535oq5m.ipfs.w3s.link/Mein%20Projekt-1(9).png",
            "description": "Album",
            "src": "https://bafybeihwmotcb35gjj6l6pyiziyxvgjb573i6y7nvb3kas6p4xfjbbyg44.ipfs.w3s.link/Awayk%20-%20Recalibrate.mp3",
            "download": "https://ann.nl.tab.digital/s/X6q4Z9iPwZj3TiB/download/Awayk%20-%20Recalibrate.mp3"

        },
        {
            "id": 10,
            "title": "Masterpiece",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Schnell",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeifhqyaagdkm6vbvdik3p5vvwjxyuth2nj5rvna2kzyclf5kukp3nu.ipfs.w3s.link/The%20Delta%20Mode%20-%20Masterpiece.mp3",
            "download": "https://ann.nl.tab.digital/s/rygkzETrtbXGF7Q/download/The%20Delta%20Mode%20-%20Masterpiece.mp3"

        },
        {
            "id": 11,
            "title": "Holly",
            "artist": "Frau Hansen",
            "album": "Single",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Traurig",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeie6gr4j5pz3dfergar2ktritghjzucltdiibfllf5ejecotheltku.ipfs.w3s.link/Frau%20Hansen.jpeg",
            "description": "Album",
            "src": "https://bafybeihdqu327kcuewielkw6vzu3quadnjdx4bjp3d3lwd7daefr4vhaqe.ipfs.w3s.link/Frau%20Hansen%20-%20Holly.mp3",
            "download": "https://ann.nl.tab.digital/s/yaH56tHpwTN8B4X/download/Frau%20Hansen%20-%20Holly.mp3"

        },
        {
            "id": 12,
            "title": "The Artist",
            "artist": "Syqem",
            "album": "Attack Of The Elephants",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeih5736gkudlwgbvxwepyqux42rshdfsqyzz5pjgqx6wstozsmvhee.ipfs.w3s.link/SQ.jpg",
            "description": "Album",
            "src": "https://bafybeiekqg5pibh3xjnd3pecdqjh5hjypbjqkw5coypjgch57jzuztgnc4.ipfs.w3s.link/Syqem%20-%20The%20Artist.mp3",
            "download": "https://ann.nl.tab.digital/s/eLcZWLSjGPzreWX/download/Syqem%20-%20The%20Artist.mp3"

        },
        {
            "id": 13,
            "title": "Dervish (Acoustic)",
            "artist": "Syqem",
            "album": "Attack Of The Elephants",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeih5736gkudlwgbvxwepyqux42rshdfsqyzz5pjgqx6wstozsmvhee.ipfs.w3s.link/SQ.jpg",
            "description": "Album",
            "src": "https://bafybeicjlpu5fkuzvbfbl2n62phgcywsqzfl4orxcryj25tkdledgpsa7a.ipfs.w3s.link/Syqem%20-%20Dervish%20(Acoustic).mp3",
            "download": "https://ann.nl.tab.digital/s/MQDt8jg3s7N6BRs/download/Syqem%20-%20Dervish%20%28Acoustic%29.mp3"


        },
        {
            "id": 14,
            "title": "Times Are Changing",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeicvfapwa27f4z5ejj6d3ktjkdc5eiyzoyjwkonzkc6rm7cj7yhjhe.ipfs.w3s.link/Awayk%20-%20Times%20Are%20Changing.mp3",
            "download": "https://ann.nl.tab.digital/s/8RQTeaWsg2XoFHP/download/Awayk%20-%20Times%20Are%20Changing.mp3"

        },
        {
            "id": 15,
            "title": "Why",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "low",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeicm2xh2cdx4j6js3efgzwqo7zyeigrnz47vctfi4ql54pxnktu2xi.ipfs.w3s.link/Awayk%20-%20Why.mp3",
            "download": "https://ann.nl.tab.digital/s/CpWGYXKaCfrxPnT/download/Awayk%20-%20Why.mp3"

        },
        {
            "id": 16,
            "title": "Popstar",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeibzrsnveog3f3p7abna6a7bkw6c5mlxfdfqvta4kn2kokihxlpgeq.ipfs.w3s.link/Awayk%20-%20Popstar.mp3",
            "download": "https://ann.nl.tab.digital/s/w8m9aPxcCXa2Pp5/download/Awayk%20-%20Popstar.mp3"

        },
        {
            "id": 17,
            "title": "Falling",
            "artist": "Liliian Garcia feat. TDM",
            "album": "Single",
            "genre": "Dance Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeid4kui34iftxolngmdjqklff5sv2dpkhhbwckn7baxhpqgwpkgrte.ipfs.w3s.link/Lilian%20Garcia.jpg",
            "description": "Album",
            "src": "https://bafybeidyu2omt3wa5rhx63ej3dlqk6lzpdligezgtkhsr5bbzc63vxbyei.ipfs.w3s.link/The%20Delta%20Mode%20ft.%20Lilian%20Garcia%20-%20Falling.mp3",
            "download": "https://ann.nl.tab.digital/s/d2gGNnM3WXCMcN3/download/The%20Delta%20Mode%20ft.%20Lilian%20Garcia%20-%20Falling.mp3"

        },
        {
            "id": 18,
            "title": "My Faith In The Mirror",
            "artist": "The Delta Mode",
            "album": "Hollywood Orchestral",
            "genre": "Cinematic",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiadc7zq5mvouvydukv5hsli77vzyqgt4rabvf4gi2ixat3ubrdf7a.ipfs.w3s.link/0%20THE%20DELTA%20MODE%20-%20HOLLYWOOD%20ORCHESTRAL.jpg",
            "description": "Album",
            "src": "https://bafybeifuasigbw7q5yxquz4ztijnszuryrn6mfnf46maflnd3r4agpgafu.ipfs.w3s.link/The%20Delta%20Mode%20-%20My%20Faith%20In%20The%20Mirror.mp3",
            "download": "https://ann.nl.tab.digital/s/6tftn2qY7n3iZ8e/download/The%20Delta%20Mode%20-%20My%20Faith%20In%20The%20Mirror.mp3"

        },
        {
            "id": 19,
            "title": "Nice",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeih6mm6awedlscsowwpsalbgjltkufmocpvpp4t3u5j3qdjtlscfke.ipfs.w3s.link/Awayk%20-%20Nice.mp3",
            "download": "https://ann.nl.tab.digital/s/pMXXpbGqXTCE2bf/download/Awayk%20-%20Nice.mp3"

        },
        {
            "id": 20,
            "title": "Lone Boy",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "medium",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeifvsy75pkfgkg4yenwggpber6bmn22x4kuh4gklzy7vs26bpeqh3q.ipfs.w3s.link/Awayk%20-%20Lone%20Boy.mp3",
            "download": "https://ann.nl.tab.digital/s/pbbzEdcdcSrzRSx/download/Awayk%20-%20Lone%20Boy.mp3"

        },
        {
            "id": 21,
            "title": "Good Morning Beautiful",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeif7qz6bjnkq6hqm5j6sssuvblzr5uimoey23ltr3rbxzowbosurqy.ipfs.w3s.link/The%20Delta%20Mode%20-%20Good%20Morning%20Beautiful.mp3",
            "download": "https://ann.nl.tab.digital/s/KyTmeJzrz3TGnja/download/The%20Delta%20Mode%20-%20Good%20Morning%20Beautiful.mp3"

        },
        {
            "id": 22,
            "title": "Bounce To The Bassline",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeifwrrnmpy7uyhc2wvrag6ji52xr5efm26qbjc754kvi27xsmygxnq.ipfs.w3s.link/The%20Delta%20Mode%20-%20Bounce%20To%20The%20Bassline%20(Cutdown)%202.mp3",
            "download": "https://ann.nl.tab.digital/s/cnq4GxCNeYNaQJN/download/The%20Delta%20Mode%20-%20Bounce%20To%20The%20Bassline%20%28Cutdown%29%202.mp3"

        },
        {
            "id": 23,
            "title": "Shake That A**",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeich6c6gzqlk7lia7wzhlzsoar2xd4zlieldm2zufs3lfhkxoj35ty.ipfs.w3s.link/The%20Delta%20Mode%20-%20Shake%20That%20A**%20(Cutdown).mp3",
            "download": "https://ann.nl.tab.digital/s/YSCQKcWd8aZSmes/download/The%20Delta%20Mode%20-%20Shake%20That%20A**%20%28Cutdown%29.mp3"

        },
        {
            "id": 24,
            "title": "Diamonds",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeifzauiyjdles6cdmts5kcivqljxf7bj66k3q7nwn5rfsiu7oksh7q.ipfs.w3s.link/The%20Delta%20Mode%20-%20Diamonds%20(Cutdown)%202.mp3",
            "download": "https://ann.nl.tab.digital/s/o4xL6HWyEEWTnYr/download/The%20Delta%20Mode%20-%20Diamonds%20%28Cutdown%29%202.mp3"

        },
        {
            "id": 25,
            "title": "Let The Good Time Roll",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeigdh6axxupshvssffaq32hdg645ldmu5iuybgwyu2haauiknyvzre.ipfs.w3s.link/The%20Delta%20Mode%20-%20Let%20The%20Good%20Time%20Roll%20(Cutdown)%202.mp3",
            "download": "https://ann.nl.tab.digital/s/jWHPfAGCwgYk7oJ/download/The%20Delta%20Mode%20-%20Let%20The%20Good%20Time%20Roll%20%28Cutdown%29.mp3"

        },
        {
            "id": 26,
            "title": "Steering Wheel",
            "artist": "Awayk",
            "album": "Pop For TV & Commercials",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeieukt4jj6ux2siccpbjxy7a4sqkdhg76kdqethiku3qggskwp5tga.ipfs.w3s.link/Cover%20Awayk.jpg",
            "description": "Album",
            "src": "https://bafybeiec6vhkreltmmdqtaksrvvbjh57ox3y6drn26tyymutdex7cdkkyy.ipfs.w3s.link/Awayk%20-%20Steering%20Wheel.mp3",
            "download": "https://ann.nl.tab.digital/s/6qMJWowo6fw8ena/download/Awayk%20-%20Steering%20Wheel.mp3"


        },
        {
            "id": 27,
            "title": "Do It All Night",
            "artist": "The Delta Mode",
            "album": "Car Commercials",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeibcq75u3by2b7ctqwbnd7pjmpzb7herfxsenzrutjnxv2rfi3kdzy.ipfs.w3s.link/CarCommercials_klein.png",
            "description": "Album",
            "src": "https://bafybeifk3xj7aruewysu2a4qjayswz63dgvjgcgiqjfeygofxjjtzo5m5y.ipfs.w3s.link/The%20Delta%20Mode%20-%20Do%20It%20All%20Night.mp3",
            "download": "https://ann.nl.tab.digital/s/TyAa739WJ6Npgfy/download/The%20Delta%20Mode%20-%20Do%20It%20All%20Night.mp3"

        },
        {
            "id": 28,
            "title": "The Traveller",
            "artist": "Dreamwood",
            "album": "Single",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreif4f2hvwv5v2tvzfryremneyprvumsw6e7sev5iplcsj2dfs3a3ya.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeig3tqkqzz5npkhbpymsifabgpnp2pedkpfdm52m3v3hoo4dv6zr7e.ipfs.w3s.link/Dreamwood%20-%20The%20Traveller.mp3",
            "download": "https://ann.nl.tab.digital/s/9oZY6qbPwE5c58S/download/Dreamwood%20-%20The%20Traveller.mp3"

        },
        {
            "id": 29,
            "title": "666",
            "artist": "The Delta Mode",
            "album": "Hollywood Orchestral",
            "genre": "Cinematic",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiadc7zq5mvouvydukv5hsli77vzyqgt4rabvf4gi2ixat3ubrdf7a.ipfs.w3s.link/0%20THE%20DELTA%20MODE%20-%20HOLLYWOOD%20ORCHESTRAL.jpg",
            "description": "Album",
            "src": "https://bafybeiabrifoffbni7mer7pim64co5yoejgq6dsc4vmeblv2g5lij43tiq.ipfs.w3s.link/The%20Delta%20Mode%20-%20666.mp3",
            "download": "https://ann.nl.tab.digital/s/Wa86nzxi4CGWGE7/download/The%20Delta%20Mode%20-%20666.mp3"
        },
        {
            "id": 30,
            "title": "Helicopters Everywhere",
            "artist": "The Delta Mode",
            "album": "Hollywood Orchestral",
            "genre": "Cinematic",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiadc7zq5mvouvydukv5hsli77vzyqgt4rabvf4gi2ixat3ubrdf7a.ipfs.w3s.link/0%20THE%20DELTA%20MODE%20-%20HOLLYWOOD%20ORCHESTRAL.jpg",
            "description": "Album",
            "src": "https://bafybeigfoaji7hegjfecrnbb3x6kf6m5i62dj5ofw3nj2az5w6d5ka56pu.ipfs.w3s.link/The%20Delta%20Mode%20-%20Helicopters%20Everywhere.mp3",
            "download": "https://ann.nl.tab.digital/s/95XaBqjXjaQ4mD8/download/The%20Delta%20Mode%20-%20Helicopters%20Everywhere.mp33"
        }, 
        {
            "id": 31,
            "title": "Siamese In Theory",
            "artist": "Syqem",
            "album": "Attack Of The Elephants",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeih5736gkudlwgbvxwepyqux42rshdfsqyzz5pjgqx6wstozsmvhee.ipfs.w3s.link/SQ.jpg",
            "description": "Album",
            "src": "https://bafybeidi66cfyys4lejsv7bfydg6p5psici5npmy5wxiygysu4uns6unwa.ipfs.w3s.link/Syqem%20-%20Siamese%20In%20Theory.mp3",
            "download": "https://ann.nl.tab.digital/s/y6MGwWr3dCHLexR/download/Syqem%20-%20Siamese%20In%20Theory.mp3"
        }, 
        {
            "id": 32,
            "title": "S.O.S.",
            "artist": "Syqem",
            "album": "Attack Of The Elephants",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeih5736gkudlwgbvxwepyqux42rshdfsqyzz5pjgqx6wstozsmvhee.ipfs.w3s.link/SQ.jpg",
            "description": "Album",
            "src": "https://bafybeifw5eaueicx3kx6hrp4msmemw2rw7esqlueyxarxuffbxanxysrbe.ipfs.w3s.link/Syqem%20-%20S.O.S.mp3",
            "download": "https://ann.nl.tab.digital/s/DcREjpGSqkE6yXB/download/Syqem%20-%20S.O.S.mp3"
        }, 
        {
            "id": 33,
            "title": "Invaded",
            "artist": "April Art",
            "album": "Fighter",
            "genre": "Alternative",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiarkjlkysp5imd3fnhsosyvcpd7r5vzmamcnptq66ldigvripx7ry.ipfs.w3s.link/AAF.jpg",
            "description": "Album",
            "src": "https://bafybeifbhjagda5trieegj4ej4ayrvlrg5et5chtdhcgdmzcbquamsupee.ipfs.w3s.link/April%20Art%20-%20Invaded.mp3",
            "download": "https://ann.nl.tab.digital/s/NqSKMKNqnkFGA6s/download/April%20Art%20-%20Invaded.mp3"
        }, 
        {
            "id": 34,
            "title": "Painkiller",
            "artist": "April Art",
            "album": "Fighter",
            "genre": "Alternative",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiarkjlkysp5imd3fnhsosyvcpd7r5vzmamcnptq66ldigvripx7ry.ipfs.w3s.link/AAF.jpg",
            "description": "Album",
            "src": "https://bafybeifvwbvxk5tv2peots2pfydcm66ukblpc2cenysen7k5tecjjnych4.ipfs.w3s.link/April%20Art%20-%20Painkiller.mp3",
            "download": "https://ann.nl.tab.digital/s/BxmwGYkXq6i9xGY/download/April%20Art%20-%20Fighter.mp3"
        }, 
        {
            "id": 35,
            "title": "Break The Silence",
            "artist": "April Art",
            "album": "Fighter",
            "genre": "Alternative",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiarkjlkysp5imd3fnhsosyvcpd7r5vzmamcnptq66ldigvripx7ry.ipfs.w3s.link/AAF.jpg",
            "description": "Album",
            "src": "https://bafybeigxhtkmhdat3xnxwcfkhitluasnkacxyxlzx3drb5tpgbanrnxqs4.ipfs.w3s.link/April%20Art%20-%20Break%20The%20Silence.mp3",
            "download": "https://ann.nl.tab.digital/s/kKtRmomZxMgrBpj/download/April%20Art%20-%20Break%20The%20Silence.mp3"
        }, 
        {
            "id": 36,
            "title": "Fighter",
            "artist": "April Art",
            "album": "Fighter",
            "genre": "Alternative",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeiarkjlkysp5imd3fnhsosyvcpd7r5vzmamcnptq66ldigvripx7ry.ipfs.w3s.link/AAF.jpg",
            "description": "Album",
            "src": "https://bafybeigl375wedrsui77mxpjc64mgy4rxqqnbdoredaefrjggvt3m5c4ga.ipfs.w3s.link/April%20Art%20-%20Fighter.mp3",
            "download": "https://ann.nl.tab.digital/s/BxmwGYkXq6i9xGY/download/April%20Art%20-%20Fighter.mp3"
        }, 
        {
            "id": 37,
            "title": "Beautiful",
            "artist": "Craig Martin",
            "album": "Best Of",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeib2yeauafvizm7qic6oyy2jowm4kxo3cqrdtm6bv74dltmkdo4iu4.ipfs.w3s.link/CRAIG.jpg",
            "description": "Album",
            "src": "https://bafybeic7v2v3tapmt6jyhkrz2upkodxlxirrsj2uwn5mm2nry72yj2wvhm.ipfs.w3s.link/Craig%20Martin%20-%20Beautiful%20(Instrumental).mp3",
            "download": "https://ann.nl.tab.digital/s/s79Kk756Mq6M9t7/download/Craig%20Martin%20-%20Beautiful%20%28Instrumental%29.mp3"
        }, 
        {
            "id": 38,
            "title": "Attack Of The Elephants",
            "artist": "Syqem",
            "album": "Attack Of The Elephants",
            "genre": "Metal",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeih5736gkudlwgbvxwepyqux42rshdfsqyzz5pjgqx6wstozsmvhee.ipfs.w3s.link/SQ.jpg",
            "description": "Album",
            "src": "https://bafybeibi6d3jxiyn76fdlsxq5frruxtzpqwclpfkbqo255ltr3oye4n4gu.ipfs.w3s.link/Syqem%20-%20Attack%20Of%20The%20Elephants.mp3",
            "download": "https://ann.nl.tab.digital/s/mBM4kriL4g4XdbQ/download/Syqem%20-%20Attack%20Of%20The%20Elephants.mp3"
        }, 
        {
            "id": 39,
            "title": "Once In A Lifetime",
            "artist": "Craig Martin",
            "album": "Best Of",
            "genre": "Pop",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafybeib2yeauafvizm7qic6oyy2jowm4kxo3cqrdtm6bv74dltmkdo4iu4.ipfs.w3s.link/CRAIG.jpg",
            "description": "Album",
            "src": "https://bafybeigu27tnposiv2txqi3gu7xxnwztqxa26lm3564nhsph4fngae445e.ipfs.w3s.link/Craig%20Martin%20-%20Once%20In%20A%20Lifetime.mp3",
            "download": "https://ann.nl.tab.digital/s/w3Hgp5NkxjM4WRd/download/Craig%20Martin%20-%20Once%20In%20A%20Lifetime.mp3"
        }, 
        {
            "id": 40,
            "title": "Destroy",
            "artist": "The Delta Mode",
            "album": "Single",
            "genre": "EDM",
            "speed": "Mittel",
            "mood": "Düster",
            "writingQuality": "high",
            "productionQuality": "medium",
            "performanceQuality": "medium",
            "coverIMG": "https://bafkreict2jzzigahmy2qvkaqlwtdixwt5sijd7o4u427iu76nksw2qfaoa.ipfs.nftstorage.link/",
            "description": "Album",
            "src": "https://bafybeib6vjpdqr6irjgnxbe3b6dr4wxuvv2wm34etafy7vhxghecm43jem.ipfs.w3s.link/The%20Delta%20Mode%20-%20Destroy.mp3",
            "download": "https://ann.nl.tab.digital/s/eCjmSsmFWLrdGwt/download/The%20Delta%20Mode%20-%20Destroy.mp3"
        }, 
    ]
}

export default songsData