// React imports
import React from "react"
import { useState } from "react"

//Routing Import
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';

//CSS Import
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//react component imports
import SideBar from "./components/SideBar"
import MusicGrid from "./components/MusicGrid"
import ArtistInfo from "./components/ArtistInfo"
import MusicPlayer from "./components/MusicPlayer"
import ProfileButton from "./components/ProfileButton";

//imports for login & authentication
import Login  from "./components/Login"
import Register  from "./components/Register"
import Reset  from "./components/Reset"
import Dashboard  from "./components/Dashboard"


const Main = () => {

  //state for musicplayer
  const [isPlaying, setIsPlaying] = useState(false)
  const [trackIndex, setTrackIndex] = useState(0)

  //state for in-app navigation
  const [navState, setNavState] = useState("Library")
  // const [viewAreaHeadline, setViewAreaHeadline] = useState("Library")

  //state for tags
  const [activeTags, setActiveTags] = useState([])

  //state for search queries
  const [searchQuery, setSearchQuery] = useState("")

  const switchPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  const switchPlayingFalse = () => {
    setIsPlaying(false)
  }

  const switchPlayingTrue = () => {
    setIsPlaying(true)
  }

  const newTrackIndex = (index) => {
    setTrackIndex(index)
  }

  function viewAreaHeadlineSelector(data) {
    return data
  }

  function viewAreaContentSelector(data) {
    if(data === "Library") {
      // setViewAreaHeadline("penisfabrik")
      return <MusicGrid searchQuery={searchQuery} activeTags={activeTags} isPlaying={isPlaying} setIsPlaying={switchPlaying} setIsPlayingFalse={switchPlayingFalse} trackIndex={trackIndex} setTrackIndex={newTrackIndex}/>
    } else if (data === "Lizensierung") {
      // setViewAreaHeadline(data)
      return <p>test</p>
    } else if (data === "Künstler") {
      // setViewAreaHeadline(data)
      return <ArtistInfo/>
    } else {
      // setViewAreaHeadline("critical error")
      return <p>critical error</p>
    }
  }

  return (
    <div className="container-fluid gx-0 h-100">
      <div className="Mainframe row gx-0">
        <div className="SideBarContainer col-auto col-md-3">
          <SideBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} activeTags={activeTags} setActiveTags={setActiveTags} navInput={setNavState} navState={navState}/>
        </div>
        <div className="ViewArea col">
          <div className="viewAreaHeader row">
            <div className="viewAreaHeadline col">
              <h2>{viewAreaHeadlineSelector(navState)}</h2>
            </div>
            <div className="profileButtonContainer col-1">
              <ProfileButton/>
            </div>
          </div>
          <div className="viewAreaContent row">
            {viewAreaContentSelector(navState)}
          </div>
        </div>
      </div>
      <MusicPlayer isPlaying={isPlaying} setIsPlaying={switchPlaying} setIsPlayingTrue={switchPlayingTrue} trackIndex={trackIndex} setTrackIndex={newTrackIndex}/>
    </div>
  );
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/main" element={<Main />} />

        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}
