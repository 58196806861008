//react imports
import React from "react"
import { useState } from "react";

//bootstrap imports
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

//Asset import
import playbutton from "../assets/icons/play.png"
import pausebutton from "../assets/icons/pause.png"

//data import
import SongsData from "../data/SongsData.js"

function MusicGrid(props){

  const [songsData, setSongs] = useState(SongsData)

  const selectSong = (index) => {

    //Check wether a new song is beeing played or the current song was started again
    if (index === props.trackIndex) {
      props.setIsPlaying()
    } else{
      if (props.isPlaying){
        props.setIsPlaying()
      }
      props.setTrackIndex(index)
    }
  }

  let moods = []
  let speeds = []
  let genres = []

  props.activeTags.map(tagsData => {
    if (tagsData.type === "mood"){
      moods = [...moods, tagsData.value]
    } else if (tagsData.type === "speed"){
      speeds = [...speeds, tagsData.value]
    } else if (tagsData.type === "genre"){
      genres = [...genres, tagsData.value]
    } else {
      console.log("ERROR: Unknown tag type")
    }
  })


  const songsRender = songsData.data.map(song => {
    if (song.id !== 0){
      const lowerCaseTitle = song.title.toLowerCase()
      const lowerCaseArtist = song.artist.toLowerCase()
      if (
        (moods.includes(song.mood) || moods.length === 0) &&
        (genres.includes(song.genre) || genres.length === 0) &&
        (speeds.includes(song.speed) || speeds.length === 0) &&
        (lowerCaseTitle.includes(props.searchQuery.toLowerCase()) || lowerCaseArtist.includes(props.searchQuery.toLowerCase()) || props.searchQuery.length === 0)
        ){
        return(
          <div style={props.variableStyles} className="MusicGridListItem row mb-14" key={song.id}>
            <h1 id="status"></h1>
            <div className="Albumcover col-1">
              <img src={song.coverIMG}></img>
            </div>
            <div className="Play col-1">
              {props.isPlaying && props.trackIndex === song.id ? <div onClick={props.setIsPlaying} id="pause"><img src={pausebutton}></img></div> : <div onClick={() => selectSong(song.id)} id="play"><img src={playbutton}></img></div>}
            </div>
            <div className="Songname col-2">
              <p>{song.title}</p>
            </div>
            <div className="Artist col-2">
              <p>{song.artist}</p>
            </div>
            <div className="Genre col-2">
              <p>{song.genre}</p>
            </div>
            <div className="Album col-2">
              <p>{song.album}</p>
            </div>
            <div className="Lizensieren col-2">
              <Button onClick={() => {console.log("under construction")}} className="test .btn-sm">Lizensieren</Button>
            </div>
          </div>
        )
      }
    }
  })

  return(
    <div className="MusicGrid container">
      {songsRender}
    </div>
  )
}
export default MusicGrid

