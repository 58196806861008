//react imports
import React, { useEffect } from "react"
import { useState } from "react";
import { Button, ToggleButton } from "react-bootstrap";

//data import
import SongsData from "../data/SongsData.js"

function MusicFilter(props){

  const [songsData, setSongs] = React.useState(SongsData)
  const [tempTagValues, setTempTagValues] = useState([])

  // states for speeds, moods & genres
  const [allSpeeds, setAllSpeeds] = React.useState([])
  const [allMoods, setAllMoods] = React.useState([])
  const [allGenres, setAllGenres] = React.useState([])

  const tags = songsData.data.map(song => {
    if (song.id !== 0){
      if (!allSpeeds.includes(song.speed)){
        allSpeeds.push(song.speed)
      }
      if (!allMoods.includes(song.mood)){
        allMoods.push(song.mood)
      }
      if (!allGenres.includes(song.genre)){
        allGenres.push(song.genre)
      }
    }
  })

  const setTag = (tag, tagType) => {
    if (props.activeTags.length === 0){
      props.setActiveTags([...props.activeTags, {value: tag, type: tagType}])
    } else {
      const tagValueArr = props.activeTags.map(tagData => tagData.value)

      if(tagValueArr.includes(tag)){
        props.setActiveTags(props.activeTags.filter(oldTags => oldTags.value !== tag))
      } else {
        props.setActiveTags([...props.activeTags, {value: tag, type: tagType}])
      }
    }
  }


  useEffect(() => {
    setTempTagValues(() => props.activeTags.map((tagsData) => tagsData.value))
  }, [props.activeTags])

  const generatedSpeed = allSpeeds.map((speed, index) => {
    return(
      <ToggleButton type="checkbox" variant="outline-primary" checked={tempTagValues.includes(speed)} onClick={() => setTag(speed, "speed")} key={speed.index}>{speed}</ToggleButton>
    )
  })

  const generatedMoods = allMoods.map((mood, index) => {
    return(
      <ToggleButton type="checkbox" variant="outline-primary" checked={tempTagValues.includes(mood)} onClick={() => setTag(mood, "mood")} key={mood.index}>{mood}</ToggleButton>
    )
  })
  const generatedGenres = allGenres.map((genre, index) => {
    return(
      <ToggleButton type="checkbox" variant="outline-primary" checked={tempTagValues.includes(genre)} onClick={() => setTag(genre, "genre")} key={genre.index}>{genre}</ToggleButton>
    )
  })

  return(
    <div className="musicFilter">
      <h5>Genre:</h5>
      {generatedGenres}
      <h5>Mood:</h5>
      {generatedMoods}
      <h5>Speed:</h5>
      {generatedSpeed}
    </div>
  )
}
export default MusicFilter

