//react import
import React from "react"
import { useState, useEffect, useRef } from "react"

//Asset imports
import PlayerSkipLinks from "../assets/icons/PlayerSkipLinks.png"
import PlayerPlay from "../assets/icons/PlayerPlay.png"
import PlayerSkipRechts from "../assets/icons/PlayerSkipRechts.png"
import pausebutton from "../assets/icons/pause.png"
import VolumeButton from "./data/MusicControl/VolumeButton.png"

//importing songsdata & mp3
import SongsData from "../data/SongsData.js"

//bottomscreen Music Controls & functionality for other components
function MusicPlayer(props){

  const [songsData, setSongsData] = useState(SongsData.data)
  // const { title, artist, coverIMG, id } = songsData[4]

  //importing sample audio (TO BE REMOVED AFTER DATABASE INTEGRATION)
  const sampleAudio = "https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/01%20Oblivious.mp3?alt=media&token=800e530f-cec7-49cd-bc03-7e2b14dd246b"

  //state for the music player
  // const [trackIndex, setTrackIndex] = useState(0)
  const [trackInfo, setTrackInfo] = useState(["title", "artist", songsData[4].coverIMG, "id"])
  const [trackProgress, setTrackProgress] = useState(0)

  // const [isPlaying, setIsPlaying] = useState(false)

  //refs for the music player
  // const audioRef = useRef(new Audio(sampleAudio))
  const audioRef = useRef(new Audio(sampleAudio))
  const intervalRef = useRef()
  const isReady = useRef(false)

  // Destructure for conciseness
	const { duration } = audioRef.current;

  //functions for the skipping songs forward and backwards //// PROBABLY NOT NEEDED /// NOT FUNCTIONAL
  // const toPrevTrack = () => {
  //   if (props.trackIndex - 1 < 0) {
  //     props.setTrackIndex(tracks.length - 1)
  //   } else {
  //     props.setTrackIndex(props.trackIndex - 1)
  //   }
  // }
  // const toNextTrack = () => {
  //   if (props.trackIndex < tracks.length - 1) {
  //     props.setTrackIndex(props.trackIndex + 1)
  //   } else {
  //     props.setTrackIndex(0)
  //   }
  // }

  const playPause = () => {
    props.setIsPlaying()
  }

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  }

  const onScrubEnd = () => {
    // If not already playing, start
    if (!props.isPlaying) {
      props.setIsPlayingTrue();
    }
    startTimer();
  }

  const startTimer = () => {
	  // Clear any timers already running
	  clearInterval(intervalRef.current);

	  intervalRef.current = setInterval(() => {
	    if (audioRef.current.ended) {
	      // toNextTrack();
	    } else {
	      setTrackProgress(audioRef.current.currentTime);
	    }
	  }, [1000]);
	}

  //play the audio in audioRef if isPlaying is true
  useEffect(() => {
    if (props.isPlaying) {
      audioRef.current.play()
      startTimer();
    } else {
      audioRef.current.pause()
      clearInterval(intervalRef.current);
    }
  }, [props.isPlaying])

  //update data of new song in the MusicPlayer
  useEffect(() => {
    songsData.map(({id, coverIMG, title, artist}) => {
    if (id === props.trackIndex) {
      setTrackInfo([title, artist, coverIMG, id])
    }
    })
  }, [props.trackIndex])

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause()
      clearInterval(intervalRef.current)
    }
  }, [])

  //when trackindex is changed, update the audioRef with the new track and prepare for the new track to be playing
  useEffect(() => {
    audioRef.current.pause()

    //map until song.id matches trackindex, then set new Audio element
    songsData.map(({id, src}) => {
      if (id === props.trackIndex){
        audioRef.current = new Audio(src)
      }
    })

    // audioRef.current = new Audio(sampleAudio)
    setTrackProgress(audioRef.current.currentTime)

    if (isReady.current) {
      var playPromise = audioRef.current.play()
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          props.setIsPlaying()
        })
        .catch(error => {
          console.log("playback error: " + error)
          props.setIsPlayingFalse()
        });
      }
      startTimer()

    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [props.trackIndex]);

  const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
  const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))`;

  const readableDurationSeconds = parseInt(duration % 60)
  const readableDurationMinutes = parseInt((duration / 60) % 60)

  const readableCurrentSeconds = parseInt(trackProgress % 60)
  const readableCurrentMinutes = parseInt((trackProgress / 60) % 60)

  return(
      <div className="musicControl d-flex row gx-0">
        <div className="TitleInfo col-3 col-md-auto">
          <img src={trackInfo[2]}></img>
          <div className="container h-100">
            <div className="MusicControlSongNameContainer row h-50">
              <h4 className="title">{trackInfo[0]}</h4>
            </div>
            <div className="MusicControlArtistNameContainer row h-50">
              <h5 className="artist">{trackInfo[1]}</h5>
            </div>
          </div>
        </div>
        <div className="PlayControl col-auto order-2">
          <div className="PlayControlButtons">
            <img src={PlayerSkipLinks}></img>
            {props.isPlaying ? <img onClick={playPause} src={pausebutton}></img> : <img onClick={playPause} src={PlayerPlay}></img>}
            <img src={PlayerSkipRechts}></img>
          </div>
          <div className="VolumeButtonBackground">
            <img src={VolumeButton}></img>
          </div>
        </div>
        <div className="MusicProgressBarContainer col-8 offset-md-0 col-md order-md-1 order-3">
          {readableCurrentSeconds < 10 ? <p>{readableCurrentMinutes}:0{readableCurrentSeconds}</p> : <p>{readableCurrentMinutes}:{readableCurrentSeconds}</p>}
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{ background: trackStyling }}
          />
          {/* <div className="MusicProgressBarBase">
            <div className="MusicProgressBarProgress"></div>
          </div> */}
          {readableDurationSeconds < 10 ? <p>{readableDurationMinutes}:0{readableDurationSeconds}</p> : <p>{readableDurationMinutes}:{readableDurationSeconds}</p>}
        </div>
      </div>
  )
}
export default MusicPlayer

